<template>
  <div style="position: relative">
    <div class="menu">
      <div
        class="item pingshenmenu"
        :class="topClass == 0 ? 'bottom-border' : ''"
        @click="changeMode('receiving')"
      >
        {{ $t("DFMEA.ReceivedTasks") }}
      </div>
      <div
        class="item pingshenmenu"
        :class="topClass == 1 ? 'bottom-border' : ''"
        @click="changeMode('sending')"
      >
        {{ $t("DFMEA.MyAssignedTasks") }}
      </div>
      <div
        class="item pingshenmenu"
        :class="topClass == 2 ? 'bottom-border' : ''"
        @click="changeMode('reviewing')"
      >
        {{ $t("DFMEA.OptimizationSteps") }}
      </div>
      <i
        class="iconfont"
        :class="is_show_chart ? 'icon-chart_on' : 'icon-chart'"
        style="
          position: relative;
          right: 0.082rem;
          top: 6px;
          color: rgb(48, 100, 143);
          font-size: 16px;
          cursor: pointer;
        "
        @click="show_chart"
      >
      </i>
    </div>
    <!-- 我收到的任务 -->
    <div v-show="(mode === 'receiving') & !is_show_chart">
      <el-table
        height="80vh"
        :data="receivingData"
        style="width: 100%; background: white"
        :header-cell-style="{
          color: '#383838',
        }"
        :cell-style="changeCellStyle"
      >
        <el-table-column
          prop="pc"
          :label="$t('DFMEA.ImprovedPrevention')"
          :min-width="240"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="cursor: pointer" @click="rowClicked(scope.row)">{{
              scope.row.pc
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ap"
          :label="$t('DFMEA.Priority')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.ap }}</template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('DFMEA.Status')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              class="pingshen-status-tag"
              :style="{
                borderStyle: 'solid',
              }"
              :class="{
                status_completed: scope.row.status == 'COMPLETED',
                status_not_confirm: scope.row.status == 'CONFIRM_NOT_YET',
                status_not_decide: scope.row.status == 'DECIDE_NOT_YET',
                status_nothing: scope.row.status == 'DO_NOTHING',
                status_not_implement: scope.row.status == 'IMPLEMENT_NOT_YET',
              }"
            >
              {{ matchStatusToName(scope.row.status) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="nodeKeyList"
          :label="$t('DFMEA.AssociatedTasks')"
        >
          <template slot-scope="scope">
            <div
              style="max-height: 100px; overflow-y: auto; overflow-x: hidden"
            >
              <span
                v-for="item in scope.row.nodeKeyList"
                :key="item"
                style="color: #ffc302; margin-right: 5px; cursor: pointer"
                @click="goto_detail(item)"
              >
                {{ item }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="targetCompleteDate"
          :label="$t('DFMEA.CompletionDate')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.targetCompleteDate
          }}</template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        :current-page="receivingPageInfo.currentPage + 1"
        background
        :page-sizes="[10, 20, 50]"
        :page-size="receivingPageInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="receivingPageInfo.total"
        @current-change="changeReceivingPage"
      >
        <el-table-column
          prop="ap"
          :label="$t('DFMEA.Priority')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.ap }}</template>
        </el-table-column>
      </el-pagination>
    </div>
    <!-- 我指派的任务 -->
    <div v-show="(mode === 'sending') & !is_show_chart">
      <el-table
        height="80vh"
        :data="sendingData"
        style="width: 100%; background: white"
        :header-cell-style="{
          color: '#383838',
        }"
        :cell-style="changeCellStyle"
      >
        <el-table-column
          prop="pc"
          :label="$t('DFMEA.ImprovedPrevention')"
          :min-width="240"
          show-overflow-tooltip
        >
          <template slot-scope="scope"
            ><span style="cursor: pointer" @click="rowClicked(scope.row)">{{
              scope.row.pc
            }}</span></template
          >
        </el-table-column>
        <el-table-column
          prop="ap"
          :label="$t('DFMEA.Priority')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.ap }}</template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('DFMEA.Status')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              class="pingshen-status-tag"
              :style="{
                borderStyle: 'solid',
              }"
              :class="{
                status_completed: scope.row.status == 'COMPLETED',
                status_not_confirm: scope.row.status == 'CONFIRM_NOT_YET',
                status_not_decide: scope.row.status == 'DECIDE_NOT_YET',
                status_nothing: scope.row.status == 'DO_NOTHING',
                status_not_implement: scope.row.status == 'IMPLEMENT_NOT_YET',
              }"
            >
              {{ matchStatusToName(scope.row.status) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="nodeKeyList"
          :label="$t('DFMEA.AssociatedTasks')"
        >
          <template slot-scope="scope">
            <div
              style="max-height: 100px; overflow-y: auto; overflow-x: hidden"
            >
              <span
                v-for="item in scope.row.nodeKeyList"
                :key="item"
                style="color: #ffc302; margin-right: 5px; cursor: pointer"
                @click="goto_detail(item)"
              >
                {{ item }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="principal"
          :label="$t('DFMEA.Assignee')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            matchUserNickname(scope.row.principal)
          }}</template>
        </el-table-column>
        <el-table-column
          prop="targetCompleteDate"
          :label="$t('DFMEA.CompletionDate')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.targetCompleteDate
          }}</template>
        </el-table-column>

        <el-table-column
          prop="title"
          :label="$t('DFMEA.More')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link" style="color: #84aace">
                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                &nbsp;&nbsp;
                <i
                  class="iconfont icon-a-216gengduo-shuxiang"
                  style="color: rgb(48, 100, 143)"
                />
                &nbsp;&nbsp;
              </span>
              <el-dropdown-menu slot="dropdown">
                <div>
                  <div class="operate_button" @click="sendAgain(scope.row)">
                    <i style="margin-right: 20px">
                      <i class="iconfont icon-fasong" />
                    </i>
                    <span>{{ $t("DFMEA.Reminder") }}</span>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        :current-page="sendingPageInfo.currentPage + 1"
        background
        :page-sizes="[10, 20, 50]"
        :page-size="sendingPageInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="sendingPageInfo.total"
        @current-change="changeSendingPage"
      >
      </el-pagination>
    </div>
    <!-- 全部优化任务 -->
    <div v-show="(mode === 'reviewing') & !is_show_chart">
      <el-table
        height="80vh"
        :data="reviewingData"
        style="width: 100%; background: white"
        :header-cell-style="{
          color: '#383838',
        }"
        :cell-style="changeCellStyle"
      >
        <el-table-column
          prop="pc"
          :label="$t('DFMEA.ImprovedPrevention')"
          :min-width="240"
          show-overflow-tooltip
        >
          <template slot-scope="scope"
            ><span style="cursor: pointer" @click="rowClicked(scope.row)">{{
              scope.row.pc
            }}</span></template
          >
        </el-table-column>
        <el-table-column
          prop="ap"
          :label="$t('DFMEA.Priority')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.ap }}</template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('DFMEA.Status')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              class="pingshen-status-tag"
              :style="{
                borderStyle: 'solid',
              }"
              :class="{
                status_completed: scope.row.status == 'COMPLETED',
                status_not_confirm: scope.row.status == 'CONFIRM_NOT_YET',
                status_not_decide: scope.row.status == 'DECIDE_NOT_YET',
                status_nothing: scope.row.status == 'DO_NOTHING',
                status_not_implement: scope.row.status == 'IMPLEMENT_NOT_YET',
              }"
            >
              {{ matchStatusToName(scope.row.status) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="nodeKeyList"
          :label="$t('DFMEA.AssociatedTasks')"
        >
          <template slot-scope="scope">
            <div
              style="max-height: 100px; overflow-y: auto; overflow-x: hidden"
            >
              <span
                v-for="item in scope.row.nodeKeyList"
                :key="item"
                style="color: #ffc302; margin-right: 5px; cursor: pointer"
                @click="goto_detail(item)"
              >
                {{ item }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="appointer"
          :label="$t('DFMEA.Initiator')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            matchUserNickname(scope.row.appointer)
          }}</template>
        </el-table-column>
        <el-table-column
          prop="principal"
          :label="$t('DFMEA.Assignee')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            matchUserNickname(scope.row.principal)
          }}</template>
        </el-table-column>
        <el-table-column
          prop="targetCompleteDate"
          :label="$t('DFMEA.CompletionDate')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.targetCompleteDate
          }}</template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        :current-page="reviewingPageInfo.currentPage + 1"
        background
        :page-sizes="[10, 20, 50]"
        :page-size="reviewingPageInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="reviewingPageInfo.total"
        @current-change="changeReviewingPage"
      >
      </el-pagination>
    </div>
    <!-- 图表 -->
    <div
      v-show="is_show_chart"
      id="echarts_main_wrap"
      ref="echarts_main_wrap"
      class="echarts_main_wrap"
    >
      <div class="pie1">
        <div id="pie1" ref="pie1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  receiving,
  appointment,
  all,
  statistics,
  sendAgain,
  editOptimization,
} from "@/network/safety/index";
import { get_file } from "@/network/gantt/index.js";
import { get_projectId } from "@/network/hara/index";

export default {
  name: "DFMEA",
  components: {},
  data() {
    return {
      topClass: 0,
      receivingData: [],
      sendingData: [],
      reviewingData: [],
      is_show_chart: false,
      mode: "receiving",
      receivingPageInfo: {
        // 我发出的邀请 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      sendingPageInfo: {
        // 我发出的邀请 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      reviewingPageInfo: {
        // 我发出的邀请 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      resize_handler: null,
      isPay: false,
      isShowPayDialog: false,
      showStatusOption: false,
      statusOptions: [
        "COMPLETED",
        "CONFIRM_NOT_YET",
        "DECIDE_NOT_YET",
        "DO_NOTHING",
        "IMPLEMENT_NOT_YET",
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getReceivingData();
  },
  beforeDestroy() {
    /* 页面组件销毁的时候，别忘了移除绑定的监听resize事件，否则的话，多渲染几次
      容易导致内存泄漏和额外CPU或GPU占用哦*/
    window.removeEventListener("resize", this.resize_handler);
  },
  methods: {
    goto_detail(nodeKey) {
      let k = nodeKey.split("-");
      get_projectId(k[0]).then((res) => {
        const { href } = this.$router.resolve({
          path: `/${res.projectId}/nodes/key/${nodeKey}`,
        });
        window.open(href, "_blank");
      });
    },
    changeCellStyle() {
      return "color:#636363; font-weight: 700";
    },
    // 再次发送提醒
    sendAgain(row) {
      const params = {
        projectId: row.projectId,
        riskAndOptimizationId: row.riskAndOptimizationId,
      };
      sendAgain(params).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("DFMEA.ms3"),
        });
      });
    },
    rowClicked(row) {
      let fileKey = row.fileKey;
      const { href } = this.$router.resolve({
        path: `/${row.projectId}/myMind/${fileKey}`,
        query: {
          dfmea: true,
        },
      });
      window.open(href, "_blank");
    },
    show_status_list() {
      this.showStatusOption = !this.showStatusOption;
    },
    matchStatusToName(status) {
      const statusMap = {
        COMPLETED: this.$t("DFMEA.Completed"),
        CONFIRM_NOT_YET: this.$t("DFMEA.NotYetDetermined"),
        DECIDE_NOT_YET: this.$t("DFMEA.NotYetDecided"),
        DO_NOTHING: this.$t("DFMEA.NoExecute"),
        IMPLEMENT_NOT_YET: this.$t("DFMEA.NotYetExecuted"),
      };
      return statusMap[status];
    },
    changeStatus(row, item) {
      // console.log(row, item);
      row.status = item;
      const params = {
        projectId: this.get_pid(),
        dfmeaDesignId: row.designId,
        data: row,
      };
      editOptimization(params).then((res) => {
        this.showStatusOption = false;
      });
    },

    // 获取我收到的任务数据
    getReceivingData() {
      const params = {
        projectId: this.get_pid(),
        query: {
          page: this.receivingPageInfo.currentPage,
          size: this.receivingPageInfo.pageSize,
        },
      };
      receiving(params).then((res) => {
        this.receivingData = res.content;
        this.receivingPageInfo.total = res.totalElements;
      });
    },
    // 获取我指派的任务数据
    getSendingData() {
      const params = {
        projectId: this.get_pid(),
        query: {
          page: this.sendingPageInfo.currentPage,
          size: this.sendingPageInfo.pageSize,
        },
      };
      appointment(params).then((res) => {
        this.sendingData = res.content;
        this.sendingPageInfo.total = res.totalElements;
      });
    },
    // 获取全部优化措施数据
    getReviewingData() {
      const params = {
        projectId: this.get_pid(),
        query: {
          page: this.reviewingPageInfo.currentPage,
          size: this.reviewingPageInfo.pageSize,
        },
      };
      all(params).then((res) => {
        this.reviewingData = res.content;
        this.reviewingPageInfo.total = res.totalElements;
      });
    },
    getNickName(id) {
      let name = "";
      const all_user_list = Object.values(this.allUserList);
      all_user_list.forEach((item) => {
        if (item.accountId === id) {
          name = item.nickname;
        }
      });
      return name;
    },
    // 点击切换评审页面内容 我收到的邀请 我发出的邀请 全部评审请求
    changeMode(mode) {
      this.mode = mode;
      this.topClass = mode == "receiving" ? 0 : mode == "sending" ? 1 : 2;
      this.is_show_chart = false;
      switch (mode) {
        case "receiving":
          this.getReceivingData();
          break;
        case "sending":
          this.getSendingData();
          break;
        case "reviewing":
          this.getReviewingData();
          break;
      }
    },
    // 控制数据试图显隐
    show_chart() {
      this.mode = "svgimg";
      this.topClass = 3;
      // 处在看板页面
      if (!this.is_show_chart) {
        this.$nextTick(() => {
          statistics({
            projectId: this.get_pid(),
          }).then((res) => {
            this.init_charts(res.statusCount);
          });
        });
      }
      this.is_show_chart = true;
    },
    changeReceivingPage(pageNumber) {
      this.receivingPageInfo.currentPage = pageNumber - 1;
      this.getReceivingData();
    },
    changeSendingPage(pageNumber) {
      this.sendingPageInfo.currentPage = pageNumber - 1;
      this.getSendingData();
    },
    changeReviewingPage(pageNumber) {
      this.reviewingPageInfo.currentPage = pageNumber - 1;
      this.getReviewingData();
    },
    debounce(fn, delay) {
      let timer = null;
      return function () {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        timer = setTimeout(() => {
          fn.resize();
        }, delay);
      };
    },
    init_charts(optionsList) {
      this.myChart_pie1 = this.$echarts.init(document.getElementById("pie1"));
      this.resize_handler = this.debounce(this.myChart_pie1, 500);
      window.addEventListener("resize", this.resize_handler);
      const pieChartData = [];
      const map = {
        CONFIRM_NOT_YET: this.$t("DFMEA.NotYetDetermined"),
        DECIDE_NOT_YET: this.$t("DFMEA.NotYetDecided"),
        IMPLEMENT_NOT_YET: this.$t("DFMEA.NotYetExecuted"),
        COMPLETED: this.$t("DFMEA.Completed"),
        DO_NOTHING: this.$t("DFMEA.NoExecute"),
      };
      optionsList.forEach((item) => {
        const obj = {
          name: map[item.status],
          value: item.count,
        };
        pieChartData.push(obj);
      });
      let option = {
        tooltip: {
          formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          right: "5%",
          top: "10%",
          width: "20%",
          height: "80%",
          textStyle: {
            width: 150,
            overflow: "breakAll",
            rich: {
              title: {
                align: "left",
              },
              value: {
                align: "right",
              },
            },
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            left: -95,
            type: "pie",
            minAngle: 5,
            radius: [0, "50%"],
            data: pieChartData,
            label: {
              show: true,
              position: "outside",
              formatter: "{b}: {c}\n{d}%",
              width: 80,
              textStyle: {
                fontSize: 12,
                fontWeight: "normal",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
        title: {
          text: this.$t("DFMEA.Status"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
          },
        },
      };
      this.myChart_pie1.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  display: flex;
  gap: 40px;
  margin-bottom: 25px;
  .bottom-border {
    border-bottom: 2px solid rgba(48, 100, 143, 1);
  }
  .item {
    font-size: 18px;
    color: #777;
    cursor: pointer;
  }
  .svgClass {
    position: relative;
    right: 0.082rem;
    top: 0.04rem;
    color: rgba(255, 255, 255, 0);
    width: 0.1042rem;
    height: 0.1042rem;
    cursor: pointer;
  }
}
.operate_button {
  display: block;
  margin: 15px 20px;
  text-align: left;
  cursor: pointer;
  color: #656667;
}
.operate_button:hover {
  color: #46b4f4;
}
.echarts_main_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  & > div {
    width: 49%;
    margin-top: 50px;
    height: 450px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 12px 0 0 20px;
    & > div {
      // width: 740px;
      width: 100%;
      height: 430px;
    }
  }
  .pie1 {
    overflow: hidden;
    width: 48%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-top: 30px;
    padding: 15px;
  }
}
.pingshen-status-tag {
  padding: 4px 16px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fff;
  border-width: 1px;
}
.status_not_confirm {
  border: 1px solid rgba(109, 127, 160, 1);
  color: rgba(109, 127, 160, 1);
}
.status_not_decide {
  color: rgba(245, 192, 34, 1);
  border: 1px solid rgba(245, 192, 34, 1);
}
.status_nothing {
  color: rgba(255, 122, 91, 1);
  border: 1px solid rgba(255, 122, 91, 1);
}
.status_not_implement {
  color: rgba(255, 147, 38, 1);
  border: 1px solid rgba(255, 147, 38, 1);
}
.status_completed {
  color: rgba(1, 186, 173, 1);
  border: 1px solid rgba(1, 186, 173, 1);
}
.workflow {
  position: relative;
  .el-button {
    width: 100%;
    height: 27px;
    max-width: fit-content;
    // line-height: 27px;
    // background: rgba(48, 100, 143, 1);
    font-size: 12px;
    font-weight: 800;
    // color: #fff;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #fff;
    border-width: 1px;
  }
  .status_not_confirm {
    border: 1px solid rgba(109, 127, 160, 1);
    color: rgba(109, 127, 160, 1);
  }
  .status_not_decide {
    color: rgba(245, 192, 34, 1);
    border: 1px solid rgba(245, 192, 34, 1);
  }
  .status_nothing {
    color: rgba(255, 122, 91, 1);
    border: 1px solid rgba(255, 122, 91, 1);
  }
  .status_not_implement {
    color: rgba(255, 147, 38, 1);
    border: 1px solid rgba(255, 147, 38, 1);
  }
  .status_completed {
    color: rgba(1, 186, 173, 1);
    border: 1px solid rgba(1, 186, 173, 1);
  }
  .workflow_select {
    z-index: 12000;
    width: 120px;
    background: #fff;
    position: absolute;
    padding: 10px 10px;
    margin-top: 6px;
    border-radius: 6px;
    left: 0;
    top: 32px;
    text-align: left;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border: 1px solid #e4e7ed;
    .workflow_item,
    .workflow_none {
      height: 32px;
      align-items: center;
      width: 100%;
      span {
        width: 100%;
        height: 100%;
        line-height: 32px;
        cursor: pointer;
      }
    }
    .workflow_item:hover {
      background: rgba(9, 30, 66, 0.06);
    }
  }
}
</style>
